import React, { CSSProperties, memo } from 'react';

import './Start.css';
import ImageGallery, { ReactImageGalleryItem, ReactImageGalleryProps } from "react-image-gallery";
// import stylesheet if you're not already using CSS @import
import "react-image-gallery/styles/css/image-gallery.css";

import routery_kierunkowe_lte from '../../assets/start/routery-kierunkowe-lte.webp';
import okablowanie_strukturalne from '../../assets/start/okablowanie-strukturalne.webp';
import sieci_mesh from '../../assets/start/sieci-mesh-new.webp';

import sygnalizacja_pozaru from '../../assets/start/sygnalizacja-pozaru.webp';
import obsluga from '../../assets/start/obsluga.webp';
import zabepieczenie_budowy from '../../assets/start/zabepieczenie-budowy.webp';

import automatyka_budynkowa from '../../assets/start/automatyka-budynkowa.webp';
import cctv from '../../assets/start/cctv.webp';
import oddymianie from '../../assets/start/oddymianie.webp';

import smarthome from '../../assets/start/smarthome.webp';
import zabezpieczenie_domow_wypoczynkowych from '../../assets/start/zabezpieczenie-domow-wypoczynkowych.webp';
import wideodomofony_ip from '../../assets/start/wideodomofony-ip.webp';
import projekty from '../../assets/start/elektro.png';
import { useTranslation } from 'react-i18next';

export interface ReactImageGalleryItemExt extends ReactImageGalleryItem {
  id: number,
  description: string
}

export function ItemRenderer(i: ReactImageGalleryItem) {
  const { i18n } = useTranslation();
  const item = i as ReactImageGalleryItemExt;
  const style = {"--img": "url(" + item.original + ")"} as CSSProperties;
  return <div className={'start-gallery-item main-content' + (i.originalClass ?? '')} style={style}>
      <h3>{i18n.t(i.originalTitle as string)}</h3>
      <div className="text">{i18n.t(i.description as string)}</div>
    </div>
}

export const MemoItemRenderer = memo(ItemRenderer, (pp, np) => {
  return (pp as ReactImageGalleryItemExt).id === (np as ReactImageGalleryItemExt).id
})

export function Start() {
  const items: ReactImageGalleryItemExt[] = [
    {
      id: 3,
      original: sieci_mesh,
      originalTitle: "start.slides.mesh_networks.title",
      description: "start.slides.mesh_networks.description"
    },
    {
      id: 2,
      original: okablowanie_strukturalne,
      originalTitle: "start.slides.structural_cabling.title",
      description: "start.slides.structural_cabling.description"
    },
    {
      id: 1,
      original: routery_kierunkowe_lte,
      originalTitle: "start.slides.lte_router.title",
      description: "start.slides.lte_router.description"
    },
    /************************************ */
    {
      id: 4,
      original: sygnalizacja_pozaru,
      originalTitle: "start.slides.fire_signaling.title",
      description: "start.slides.fire_signaling.description"
    },
    {
      id: 5,
      original: obsluga,
      originalTitle: "start.slides.production_service.title",
      description: "start.slides.production_service.description"
    },
    {
      id: 6,
      original: zabepieczenie_budowy,
      originalTitle: "start.slides.construction_security.title",
      description: "start.slides.construction_security.description"
    },
    /************************************ */
    {
      id: 7,
      original: automatyka_budynkowa,
      originalTitle: "start.slides.building_automation.title",
      description: "start.slides.building_automation.description"
    },
    {
      id: 8,
      original: cctv,
      originalTitle: "start.slides.cctv.title",
      description: "start.slides.cctv.description"
    },
    {
      id: 9,
      original: oddymianie,
      originalTitle: "start.slides.smoke_detection.title",
      description: "start.slides.smoke_detection.description"
    },
    {
      id: 10,
      original: projekty,
      originalTitle: "start.slides.projects.title",
      description: "start.slides.projects.description"
    },
  ]

  return <ImageGallery
    items={items}
    infinite={true}
    showNav={false}
    showFullscreenButton={false}
    showPlayButton={false}
    showBullets={true}
    autoPlay={true}
    renderItem={(p) => <MemoItemRenderer {...p}></MemoItemRenderer>}
    additionalClass='start-gallery'
  />
}
