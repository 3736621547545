import React from 'react';

import './ConfigItem.css';

export function ConfigItem(props: {
  label: string,
  img: string,
  children?: any
}) {
  return <label className='ConfigItem'>
    {props.children}
    <div className='header'>
      <img src={props.img} />
      <span>{props.label}</span>
    </div>
  </label>
}