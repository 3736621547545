import { useRef, useState } from 'react';
import { ConfigItem } from '../../../Form/components/ConfigItem/ConfigItem';

import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from 'react-router-dom';
import { ContactForm } from '../../../Contact/Contact';
import { Accordion } from '../../../Form/components/Accordion/Accordion';
import { NumberField } from '../../../Form/components/NumberField/NumberField';
import './Cctv.css';

export interface ConfigElement {
  name: string,
  img: string,
  id: number,
  priceNet: number,
  priceGross: number
}

export type Camera = ConfigElement & {
  pixelsPerFrame: number,
  analytics: boolean,
  motoZoom: boolean,
  color: "white" | "black"
  // alternativeId: number
}

export type Recorder = ConfigElement & {
  analytics: boolean,
};

export type DataStorage = (ConfigElement & {
  capacityBytes: number
})

export interface CctvConfigOptions {
  analyticsRecorder: Recorder,
  noAnalyticsRecorder: Recorder,
  integration: ConfigElement
  cameras: Camera[],
  installationMethod: ConfigElement[],
  dataStorage: DataStorage[],
  installationStorage: ConfigElement[]
}

export interface CctvConfigForm extends ContactForm {
  recorder: string,
  integrations: string,
  cameras: { camera: Camera, count?: number }[],
  installationMethod: string,
  dataStorage: string,
  installationStorage: string,
  cabling: string
}

export interface CctvConfig {
  recorder: Recorder,
  integrations: number,
  cameras: { camera: Camera, count: number }[],
  installationMethod: ConfigElement,
  dataStorage: DataStorage,
  installationStorage: ConfigElement,
  cabling: string
}

export enum Cabling {
  EMPTY = "",
  NO_CABLING = "no_cabling",
  BAD_CABLING = "bad_cabling",
  GOOD_CABLING = "good_cabling"
}

const RESOLUTIONS = {
  "HD1 (704x240)": 168960,
  "D1 (704x480)": 337920,
  "CIF (352x240)": 84480,
  "1MP (1280x720)": 921600,
  "1.3MP (1280x1024)": 1310720,
  "2MP (1920x1080)": 2073600,
  "3MP (2048x1536)": 3145728,
  "4MP (2688x1520)": 4085760,
  "5MP (2592x1944)": 5038848,
  "6MP (3072x2048)": 6291456,
  "8MP (4K)": 8294400,
  "12MP (4000x3072)": 12288000,
  "33.2MP (8K)": 33177600,
}


const img = "https://b2b.eltrox.pl/media/catalog/product/cache/0/small_image/450x450/9df78eab33525d08d6e5fb8d27136e95/0/_/0_2_3_10586_1_8149_1_13171_1_2762_1_1532_1_460_1_42_1_6773_1_13_1_5514_1_2398.jpg";
export const OPTIONS: CctvConfigOptions = {
  analyticsRecorder: {
    name: "Rejestrator analityka",
    img: img,
    id: 1,
    priceNet: 1,
    priceGross: 2,
    analytics: true
  },
  noAnalyticsRecorder: {
    name: "Rejestrator brak analityki",
    img: img,
    id: 2,
    priceNet: 1,
    priceGross: 2,
    analytics: false
  },
  integration: {
    name: "Więcej niż 2 integracje",
    img: img,
    id: 3,
    priceNet: 1,
    priceGross: 2
  },
  cameras: [
    {
      name: "Kamera bullet/analityka/motozoom/biała",
      img: img,
      id: 4,
      priceNet: 1,
      priceGross: 2,
      pixelsPerFrame: RESOLUTIONS["4MP (2688x1520)"],
      analytics: true,
      motoZoom: true,
      color: "white"
    },
    {
      name: "Kamera bullet/analityka/motozoom/czarna",
      img: img,
      id: 5,
      priceNet: 1,
      priceGross: 2,
      analytics: true,
      pixelsPerFrame: RESOLUTIONS["4MP (2688x1520)"],
      motoZoom: true,
      color: "black"
    },
    {
      name: "Kamera eyeball/analityka/motozoom/biała",
      img: img,
      id: 6,
      priceNet: 1,
      priceGross: 2,
      analytics: true,
      pixelsPerFrame: RESOLUTIONS["4MP (2688x1520)"],
      motoZoom: true,
      color: "white"
    },
    {
      name: "Kamera eyeball/analityka/motozoom/czarna",
      img: img,
      id: 7,
      priceNet: 1,
      priceGross: 2,
      analytics: true,
      pixelsPerFrame: RESOLUTIONS["4MP (2688x1520)"],
      motoZoom: true,
      color: "black"
    },
    /* nie-motozoom*/
    {
      name: "Kamera bullet/analityka/nie-motozoom/biała",
      img: img,
      id: 8,
      priceNet: 1,
      priceGross: 2,
      pixelsPerFrame: RESOLUTIONS["4MP (2688x1520)"],
      analytics: true,
      motoZoom: false,
      color: "white"
    },
    {
      name: "Kamera bullet/analityka/nie-motozoom/czarna",
      img: img,
      id: 9,
      priceNet: 1,
      priceGross: 2,
      analytics: true,
      pixelsPerFrame: RESOLUTIONS["4MP (2688x1520)"],
      motoZoom: false,
      color: "black"
    },
    {
      name: "Kamera eyeball/analityka/nie-motozoom/biała",
      img: img,
      id: 10,
      priceNet: 1,
      priceGross: 2,
      analytics: true,
      pixelsPerFrame: RESOLUTIONS["4MP (2688x1520)"],
      motoZoom: false,
      color: "white"
    },
    {
      name: "Kamera eyeball/analityka/nie-motozoom/czarna",
      img: img,
      id: 11,
      priceNet: 1,
      priceGross: 2,
      analytics: true,
      pixelsPerFrame: RESOLUTIONS["4MP (2688x1520)"],
      motoZoom: false,
      color: "black"
    },
    /* nie analityka */
    {
      name: "Kamera bullet/nie-analityka/motozoom/biała",
      img: img,
      id: 12,
      priceNet: 1,
      priceGross: 2,
      pixelsPerFrame: RESOLUTIONS["4MP (2688x1520)"],
      analytics: false,
      motoZoom: true,
      color: "white"
    },
    {
      name: "Kamera bullet/nie-analityka/motozoom/czarna",
      img: img,
      id: 13,
      priceNet: 1,
      priceGross: 2,
      analytics: false,
      pixelsPerFrame: RESOLUTIONS["4MP (2688x1520)"],
      motoZoom: true,
      color: "black"
    },
    {
      name: "Kamera eyeball/nie-analityka/motozoom/biała",
      img: img,
      id: 14,
      priceNet: 1,
      priceGross: 2,
      analytics: false,
      pixelsPerFrame: RESOLUTIONS["4MP (2688x1520)"],
      motoZoom: true,
      color: "white"
    },
    {
      name: "Kamera eyeball/nie-analityka/motozoom/czarna",
      img: img,
      id: 15,
      priceNet: 1,
      priceGross: 2,
      analytics: false,
      pixelsPerFrame: RESOLUTIONS["4MP (2688x1520)"],
      motoZoom: false,
      color: "black"
    },
    /* nie analityka, nie motozoom */
    {
      name: "Kamera bullet/nie-analityka/nie-motozoom/biała",
      img: img,
      id: 16,
      priceNet: 1,
      priceGross: 2,
      pixelsPerFrame: RESOLUTIONS["4MP (2688x1520)"],
      analytics: false,
      motoZoom: false,
      color: "white"
    },
    {
      name: "Kamera bullet/nie-analityka/nie-motozoom/czarna",
      img: img,
      id: 17,
      priceNet: 1,
      priceGross: 2,
      analytics: false,
      pixelsPerFrame: RESOLUTIONS["4MP (2688x1520)"],
      motoZoom: false,
      color: "black"
    },
    {
      name: "Kamera eyeball/nie-analityka/nie-motozoom/biała",
      img: img,
      id: 18,
      priceNet: 1,
      priceGross: 2,
      analytics: false,
      pixelsPerFrame: RESOLUTIONS["4MP (2688x1520)"],
      motoZoom: false,
      color: "white"
    },
    {
      name: "Kamera eyeball/nie-analityka/nie-motozoom/czarna",
      img: img,
      id: 19,
      priceNet: 1,
      priceGross: 2,
      analytics: false,
      pixelsPerFrame: RESOLUTIONS["4MP (2688x1520)"],
      motoZoom: false,
      color: "black"
    }
  ],
  installationMethod: [
    {
      name: "Montaż na płasko",
      img: img,
      id: 20,
      priceNet: 1,
      priceGross: 2
    },
    {
      name: "Montaż kątowo",
      img: img,
      id: 21,
      priceNet: 1,
      priceGross: 2
    },
    {
      name: "Montaż bez adaptera",
      img: img,
      id: 22,
      priceNet: 1,
      priceGross: 2
    },
    {
      name: "Montaż na słupie",
      img: img,
      id: 23,
      priceNet: 1,
      priceGross: 2
    }
  ],
  dataStorage: [
    {
      name: "2TB",
      img: img,
      id: 24,
      priceNet: 1,
      priceGross: 2,
      capacityBytes: 2_199_023_255_552
    },
    {
      name: "4TB",
      img: img,
      id: 25,
      priceNet: 1,
      priceGross: 2,
      capacityBytes: 4_398_046_511_104
    },
    {
      name: "6TB",
      img: img,
      id: 26,
      priceNet: 1,
      priceGross: 2,
      capacityBytes: 6_597_069_766_656
    }
  ],
  installationStorage: [
    {
      name: "Szafa 1",
      img: img,
      id: 27,
      priceNet: 1,
      priceGross: 2,
    },
    {
      name: "Szafa 2",
      img: img,
      id: 28,
      priceNet: 1,
      priceGross: 2,
    },
    {
      name: "Szafa 3",
      img: img,
      id: 29,
      priceNet: 1,
      priceGross: 2,
    },
    {
      name: "Szafa 4",
      img: img,
      id: 30,
      priceNet: 1,
      priceGross: 2,
    }
  ]
};

function getFormatQualityActivity(
  videoFormat: "mpeg" | "h264" | "h265" | "h265plus",
  quality: "low" | "medium" | "high",
  activityLevel: "low" | "medium" | "high"
): number {
  let fca: number = 0;
  if (videoFormat === "mpeg") {
    if (quality === "high") {
      if (activityLevel === "high") {
        fca = 16;
      }
      if (activityLevel === "medium") {
        fca = 16;
      }
      if (activityLevel === "low") {
        fca = 16;
      }
    }
    if (quality === "medium") {
      if (activityLevel === "high") {
        fca = 25;
      }
      if (activityLevel === "medium") {
        fca = 25;
      }
      if (activityLevel === "low") {
        fca = 25;
      }
    }
    if (quality === "low") {
      if (activityLevel === "high") {
        fca = 31;
      }
      if (activityLevel === "medium") {
        fca = 31;
      }
      if (activityLevel === "low") {
        fca = 31;
      }
    }
    return fca;
  }
  if (videoFormat === "h264") {
    if (quality === "high") {
      if (activityLevel === "high") {
        fca = 98;
      }
      if (activityLevel === "medium") {
        fca = 102;
      }
      if (activityLevel === "low") {
        fca = 105;
      }
    }
    if (quality === "medium") {
      if (activityLevel === "high") {
        fca = 160;
      }
      if (activityLevel === "medium") {
        fca = 173;
      }
      if (activityLevel === "low") {
        fca = 181;
      }
    }
    if (quality === "low") {
      if (activityLevel === "high") {
        fca = 220;
      }
      if (activityLevel === "medium") {
        fca = 242;
      }
      if (activityLevel === "low") {
        fca = 260;
      }
    }
    return fca;
  }
  if (videoFormat === "h265") {
    if (quality === "high") {
      if (activityLevel === "high") {
        fca = 260;
      }
      if (activityLevel === "medium") {
        fca = 272;
      }
      if (activityLevel === "low") {
        fca = 282;
      }
    }
    if (quality === "medium") {
      if (activityLevel === "high") {
        fca = 480;
      }
      if (activityLevel === "medium") {
        fca = 510;
      }
      if (activityLevel === "low") {
        fca = 536;
      }
    }
    if (quality === "low") {
      if (activityLevel === "high") {
        fca = 620;
      }
      if (activityLevel === "medium") {
        fca = 670;
      }
      if (activityLevel === "low") {
        fca = 710;
      }
    }
    return fca;
  }
  if (videoFormat === "h265plus") {
    if (quality === "high") {
      if (activityLevel === "high") {
        fca = 335;
      }
      if (activityLevel === "medium") {
        fca = 351;
      }
      if (activityLevel === "low") {
        fca = 364;
      }
    }
    if (quality === "medium") {
      if (activityLevel === "high") {
        fca = 590;
      }
      if (activityLevel === "medium") {
        fca = 621;
      }
      if (activityLevel === "low") {
        fca = 643;
      }
    }
    if (quality === "low") {
      if (activityLevel === "high") {
        fca = 776;
      }
      if (activityLevel === "medium") {
        fca = 834;
      }
      if (activityLevel === "low") {
        fca = 833;
      }
    }
    return fca;
  }
  return 0;
}

function getBandwidth(
  numberOfCameras: number,
  pixelsPerFrame: number,
  fps: number,
  fqa: number
) {
  const bitsPerPixel = pixelsPerFrame > 8294399 ? 30 : 16;
  // bytes per second
  return pixelsPerFrame * (bitsPerPixel / 8) * fps * (numberOfCameras / fqa);
}

function getBytes(
  numberOfCameras: number,
  daysToStore: number,
  pixelsPerFrame: number,
  fps: number,
  hoursPerDay: number,
  fqa: number
) {
  // const pixels = RESOLUTIONS[resolutionName]
  const hoursPerDayFactor = hoursPerDay / 24;
  const seconds = 86400 * daysToStore * hoursPerDayFactor;
  const bandwidth = getBandwidth(numberOfCameras, pixelsPerFrame, fps, fqa);
  return Math.ceil(seconds * bandwidth);
}

function getSeconds(
  dataStorageBytes: number,
  numberOfCameras: number,
  pixelsPerFrame: number,
  fps: number,
  fqa: number
) {
  const bandwidth = getBandwidth(numberOfCameras, pixelsPerFrame, fps, fqa);
  return Math.floor(dataStorageBytes / bandwidth);
}

function formatBytes(bytes: number) {
  if (bytes < 1_000) {
    return bytes + "B";
  } else if (bytes < 1_000_000) {
    return (bytes / 1_000).toFixed(2) + "KB";
  } else if (bytes < 1_000_000_000) {
    return (bytes / 1_000_000).toFixed(2) + "MB";
  } else if (bytes < 1_000_000_000_000) {
    return (bytes / 1_000_000_000).toFixed(2) + "GB";
  } else if (bytes < 1_000_000_000_000_000) {
    return (bytes / 1_000_000_000_000).toFixed(2) + "TB";
  } else if (bytes < 1_000_000_000_000_000_000) {
    return (bytes / 1_000_000_000_000_000).toFixed(2) + "PB";
  } else {
    return "";
  }
}

function formatSeconds(seconds: number) {
  const days = Math.floor(seconds / 86400);
  seconds -= (days * 86400);
  const hours = Math.floor(seconds / 3600);
  seconds -= (hours * 3600);
  const minutes = Math.floor(seconds / 60);
  seconds -= (minutes * 60);

  if (days === 0) {
    if (hours === 0) {
      if (minutes === 0) {
        return seconds + "s";
      } else {
        return minutes + "min " + seconds + "s";
      }
    } else {
      return hours + "h " + minutes + "min " + seconds + "s";
    }
  } else {
    return days + "d  " + hours + "h " + minutes + "min " + seconds + "s";
  }
}


export function Cctv() {
  const { i18n } = useTranslation();
  const options = useLoaderData() as CctvConfigOptions;
  const ref = useRef<HTMLFormElement>(null);
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid, submitCount },
    setValue,
    getValues
  } = useForm<CctvConfigForm>({
    defaultValues: { cameras: options.cameras.map(e => ({ camera: e })) }
  })
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control,
    name: "cameras",
    rules: {
      validate: {
        atLeastOneCamera: e => e.filter(i => i.count ?? 0 > 0).length > 0 ? true : "Należy wybrać przynajmniej jedną kamerę"
      }
    }
  });


  const cablingDict = [
    { key: Cabling.EMPTY, value: "Wybierz" },
    { key: Cabling.NO_CABLING, value: "Nie mam jeszcze żadnej instalacji lub moja instalacja nie ma jeszcze zainstalowanego okablowania" },
    { key: Cabling.BAD_CABLING, value: "Moja instalacja posiada okablowanie ale jest innego rodzaju niż skrętka UTP lub jest niższej klasy niż 5e" },
    { key: Cabling.GOOD_CABLING, value: "Moja instalacja posiada okablowanie rodzaju UTP klasy 5e lub lepszej" },
  ];

  function mapFormToModel(f: CctvConfigForm) {
    const recorderId = parseInt(f.recorder)
    const integrationsId = parseInt(f.integrations)

    const cameras = f.cameras.filter(e => e.count ?? 0 > 0) as { camera: Camera, count: number }[];
    const installationId = parseInt(f.installationMethod)
    const dataStorageId = parseInt(f.dataStorage)
    const storageId = parseInt(f.installationStorage)

    const newConfig: Partial<CctvConfig> = {};
    newConfig.recorder = [options.analyticsRecorder, options.noAnalyticsRecorder].find(r => r.id === recorderId);
    newConfig.integrations = integrationsId;
    newConfig.cameras = cameras;
    newConfig.installationMethod = options.installationMethod.find(i => i.id === installationId);
    newConfig.dataStorage = options.dataStorage.find(i => i.id === dataStorageId);
    newConfig.installationStorage = options.installationStorage.find(i => i.id === storageId);
    newConfig.cabling = f.cabling ?? Cabling.NO_CABLING;
    return newConfig;
  }

  function onSubmit(f: CctvConfigForm) {
    console.log(mapFormToModel(f));
  }

  const [accordionStates, setAccordionStates] = useState([true, false, false, false, false, false, false, false]);
  const onOpenChange = (i: number) => (newState: boolean) => {
    const newStates = accordionStates.map(e => false);
    newStates[i] = newState;
    setAccordionStates(newStates);
  };
  const gotoStep = (i: number) => () => {
    onOpenChange(i)(true);
    setTimeout(() => {
      ref.current?.children[i]?.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
    }, 20);
  };

  const model = mapFormToModel(getValues());
  const cameraCount = (model.cameras ?? []).map(e => e.count).reduce((acc, x) => acc + x, 0);

  return <div className='main-content Cctv'>
    <h3>Konfigurator instalacji CCTV</h3>
    <div className='text-bg' style={{ padding: 0 }}>
      <form onSubmit={handleSubmit(onSubmit, e => console.log(e))} ref={ref}>
        <Accordion title='Krok 1. Wybierz rejestrator' className='recorder-section' open={accordionStates[0]} onOpenChange={onOpenChange(0)} error={errors.recorder?.message}>
          <ConfigItem label={options.analyticsRecorder.name} img={options.analyticsRecorder.img}>
            <input type="radio" {...register("recorder", { required: "Pole wymagane", onChange: gotoStep(1) })} radioGroup="recorder" defaultValue={options.analyticsRecorder.id} />
          </ConfigItem>
          <ConfigItem label={options.noAnalyticsRecorder.name} img={options.noAnalyticsRecorder.img}>
            <input type="radio" {...register("recorder", { required: "Pole wymagane", onChange: gotoStep(1) })} radioGroup="recorder" defaultValue={options.noAnalyticsRecorder.id} />
          </ConfigItem>
        </Accordion>
        <Accordion title='Krok 2. Wskaż poziom integracji' className='rsmodule-section' open={accordionStates[1]} onOpenChange={onOpenChange(1)} error={errors.integrations?.message}>
          <ConfigItem label='Więcej niż 2 integracje' img={img}>
            <input type="radio" {...register("integrations", { required: "Pole wymagane", onChange: gotoStep(2) })} radioGroup="integrations" defaultValue={options.integration.id} />
          </ConfigItem>
          <ConfigItem label='2 lub mniej integracje' img={img}>
            <input type="radio" {...register("integrations", { required: "Pole wymagane", onChange: gotoStep(2) })} radioGroup="integrations" defaultValue={options.integration.id} />
          </ConfigItem>
        </Accordion>
        <Accordion title='Krok 3. Wybierz kamery' className='camera-section' open={accordionStates[2]} onOpenChange={onOpenChange(2)} error={errors.cameras?.root?.message || (errors.cameras?.length ? "Rozwiń aby zobaczyć błędy walidacji" : "")}>
          {fields.map((c, index) => {
            const error = errors.cameras?.[index]?.message;
            return <ConfigItem key={c.id} label={c.camera.name} img={c.camera.img}>
              {error ? <span className='error'>{error}</span> : ""}
              <NumberField setValue={setValue as any} inputAttrs={register("cameras[" + index + "]" as any, {
                validate: {
                  min: e => !e.count || e.count >= 0 ? true : "Wartość musi być nieujemna",
                  max: e => !e.count || e.count <= 99 ? true : "Wartość musi być mniejsza niż 100"
                }, setValueAs: (v: any) => {
                  return { camera: c.camera, count: parseInt(v) };
                }
              })}></NumberField>
            </ConfigItem>
          })}
          <button type="button" style={{ gridColumn: "1 / -1" }} onClick={gotoStep(3)}>Wybrałem kamery, przejdź do kroku 4</button>
        </Accordion>
        <Accordion title='Krok 4. Wybierz sposób montażu' className='installation-section' open={accordionStates[3]} onOpenChange={onOpenChange(3)} error={errors.installationMethod?.message}>
          {options.installationMethod.map(i => {
            return <ConfigItem key={i.id} label={i.name} img={i.img}>
              <input type="radio" {...register("installationMethod", { required: "Pole wymagane", onChange: gotoStep(4) })} radioGroup="installationMethod" defaultValue={i.id} />
            </ConfigItem>;
          })}
        </Accordion>
        <Accordion title='Krok 5. Wybierz dysk' className='harddrive-section' open={accordionStates[4]} onOpenChange={onOpenChange(4)} error={errors.dataStorage?.message}>
          {options.dataStorage.map(i => {
            const cameras = getValues().cameras?.filter(e => e.count ?? 0 > 0) as { camera: Camera, count: number }[];
            let formatted = "";
            if (cameras?.length) {

              const pixelsPerFrame = cameras.map(e => e.camera.pixelsPerFrame * e.count).reduce((acc, x) => acc + x) / cameraCount;
              const seconds = getSeconds(
                i.capacityBytes,
                cameraCount,
                pixelsPerFrame,
                15,
                getFormatQualityActivity(
                  'h264',
                  'low',
                  "high"
                )
              );

              formatted = formatSeconds(seconds);
            }

            return <ConfigItem key={i.id} label={i.name} img={i.img}>
              <input type="radio" {...register("dataStorage", { required: "Pole wymagane", onChange: gotoStep(5) })} radioGroup="dataStorage" defaultValue={i.id} />
              {formatted ? "Szacowany czas składowanych nagrań: " + formatted : ""}
            </ConfigItem>;
          })}
        </Accordion>
        <Accordion title='Krok 6. Wybierz miejsce przechowywania' className='storage-section' open={accordionStates[5]} onOpenChange={onOpenChange(5)} error={errors.installationStorage?.message}>
          {options.installationStorage.map(i => {
            return <ConfigItem key={i.id} label={i.name} img={i.img}>
              <input type="radio" {...register("installationStorage", { required: "Pole wymagane", onChange: gotoStep(6) })} radioGroup="installationStorage" defaultValue={i.id} />
            </ConfigItem>;
          })}
        </Accordion>
        <Accordion title='Krok 7. Okablowanie' className='cabling-section' open={accordionStates[6]} onOpenChange={onOpenChange(6)} error={errors.cabling?.message}>
          <label>
            <select {...register("cabling", { validate: { nonEmpty: e => e === Cabling.EMPTY ? "Pole wymagane" : true }, onChange: gotoStep(7) })} style={{ width: "100%" }} defaultValue={Cabling.EMPTY}>
              {
                cablingDict.map(e => <option key={e.key} value={e.key}>{e.value}</option>)
              }
            </select>
            <span>Czy Twoja instalacja ma już zainstalowane okablowanie?</span>
          </label>
        </Accordion>
        <Accordion title='Podsumowanie' className='summary-section' open={accordionStates[7]} onOpenChange={onOpenChange(7)} error={submitCount > 0 && !isValid ? "Formularz zawiera błędy walidacji. Proszę uzupełnić brakujące pola." : ""}>
          <div className='container-h'>
            <div className='contact-form' style={{flexGrow: 1}}>
              <label>
                {errors.name?.message ? <span className='error'>{errors.name?.message}</span> : ""}
                <input {...register("name", { required: "Pole wymagane" })} type="text" />
                <span>{i18n.t("contact.form.name")}</span>
              </label>
              <label>
                {errors.email?.message ? <span className='error'>{errors.email?.message}</span> : ""}
                <input {...register("email", { required: "Pole wymagane" })} type="email" />
                <span>E-mail</span>
              </label>
              <label>
                {errors.phone?.message ? <span className='error'>{errors.phone?.message}</span> : ""}
                <input {...register("phone", { required: "Pole wymagane" })} type="tel" />
                <span>{i18n.t("contact.form.phone")}</span>
              </label>
              <label>
                {errors.address?.message ? <span className='error'>{errors.address?.message}</span> : ""}
                <input {...register("address", { required: "Pole wymagane" })} type="text" />
                <span>{i18n.t("contact.form.address")}</span>
              </label>
              <label className='span-2'>
                {errors.content?.message ? <span className='error'>{errors.content?.message}</span> : ""}
                <textarea style={{ height: "10em" }} maxLength={3000}/>
                <span>{i18n.t("contact.form.content")}</span>
              </label>
            </div>
            <div className='summary-text'>
              <ul>
                {model.recorder ? <ol>Rejestrator: {model.recorder.name} {model.recorder.priceGross}PLN</ol> : ""}
                {model.integrations ? <ol>Integracje: {model.integrations} {"cena integracji"}PLN</ol> : ""}
                {model.cameras?.length ? <ol>kamery:
                  <ul>
                    {model.cameras.map(c => {
                      return <ol key={c.camera.id}>{c.camera.name} {c.count} x {c.camera.priceGross}PLN</ol>;
                    })}
                  </ul>
                </ol> : ""}
                {model.installationMethod ? <ol>Sposób montażu kamer: {model.installationMethod.name} {cameraCount} x {model.installationMethod.priceGross}PLN</ol> : ""}
                {model.dataStorage ? <ol>Dysk twardy: {model.dataStorage.name} {model.dataStorage.priceGross}PLN</ol> : ""}
                {model.installationStorage ? <ol>Miescje przechowywania instalacji: {model.installationStorage.name} {model.installationStorage.priceGross}PLN</ol> : ""}
                {model.cabling === Cabling.NO_CABLING ? <ol>Brak instalacji/okablowania: cena?PLN</ol> : ""}
                {model.cabling === Cabling.BAD_CABLING ? <ol>Okablowanie inne niż wymagane: cena?PLN</ol> : ""}
              </ul>
            </div>
          </div>
          <button type="submit">Wyślij zapytanie</button>
        </Accordion>
      </form>
    </div>
  </div >
}
