import React from 'react';

import './Certificates.css';
import { useTranslation } from 'react-i18next';
import ReactImageGallery from 'react-image-gallery';
import { MemoItemRenderer, ReactImageGalleryItemExt } from '../Start/Start';

import dh from '../../assets/certs/certyfikat_dh.webp';
import fibaro from '../../assets/certs/certyfikat_fibaro.webp';
import fiber from '../../assets/certs/certyfikat_fiber.webp';
import grenton1 from '../../assets/certs/certyfikat_grenton.webp';
import grenton2 from '../../assets/certs/certyfikat_grenton_lvII.webp';
import mikrotik from '../../assets/certs/certyfikat_mikrotik.png';
import roger from '../../assets/certs/certyfikat_roger.webp';
import dahua from '../../assets/certs/DHSA_Training_Certificate_for_Mateusz_Lis.png';
import sep from '../../assets/certs/sepy.webp';

export function Certificates() {
  const items: ReactImageGalleryItemExt[] = [
    {
      id: 1,
      original: dh,
      originalTitle: "certificates.slides.dh.title",
      description: "certificates.slides.dh.description"
    },
    {
      id: 2,
      original: fibaro,
      originalTitle: "certificates.slides.fibaro.title",
      description: "certificates.slides.fibaro.description"
    },
    {
      id: 3,
      original: fiber,
      originalTitle: "certificates.slides.fiber.title",
      description: "certificates.slides.fiber.description"
    },
    {
      id: 4,
      original: grenton2,
      originalTitle: "certificates.slides.grenton2.title",
      description: "certificates.slides.grenton2.description"
    },
    {
      id: 5,
      original: mikrotik,
      originalTitle: "certificates.slides.mikrotik.title",
      description: "certificates.slides.mikrotik.description"
    },
    {
      id: 6,
      original: roger,
      originalTitle: "certificates.slides.roger.title",
      description: "certificates.slides.roger.description"
    },
    {
      id: 7,
      original: dahua,
      originalTitle: "certificates.slides.dahua.title",
      description: "certificates.slides.dahua.description"
    },
    {
      id: 8,
      original: sep,
      originalTitle: "certificates.slides.sep.title",
      description: "certificates.slides.sep.description",
    },
  ];

  return <ReactImageGallery
    items={items}
    infinite={true}
    showNav={false}
    showFullscreenButton={false}
    showPlayButton={false}
    showBullets={true}
    autoPlay={true}
    renderItem={(p) => <MemoItemRenderer {...p}></MemoItemRenderer>}
    additionalClass='start-gallery Certificates'
  />
}
