import React, { useState } from 'react';

import './Accordion.css';
import { error } from 'console';

export function Accordion(props: {
  open?: boolean,
  onOpenChange?: (open: boolean) => void,
  className?: string,
  title: string,
  error?: string,
  children: any
}) {
  const [open, setOpen] = useState(props.open ?? false);
  if(props.open != null && props.open != open) {
    setOpen(props.open);
  }
  return <div className={'Accordion ' + (props.className ?? '')} data-open={open}>
        <div className='header'>
          <div className='title-bar'>
            <h4 className='title' onClick={() => { setOpen(!open); props.onOpenChange?.(!open); }}>{props.title}</h4>
            <div className='control' onClick={() => { setOpen(!open); props.onOpenChange?.(!open); }}></div>
          </div>
          { props.error ? (<span className='error'>{props.error}</span>) : ""}
        </div>
        <div className='body'>
          {props.children}
        </div>
      </div>
}
