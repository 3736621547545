
import React from 'react';

import './Contact.css';
import { useTranslation } from 'react-i18next';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { Icon, LatLngTuple } from 'leaflet';
import "leaflet/dist/leaflet.css";
import markerIcon from '../../assets/marker-icon.png'
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

export interface ContactForm {
  name: string,
  email: string,
  phone: string,
  address: string,
  subject: string,
  content: string
}

export function Contact() {
  const { i18n } = useTranslation();
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    getValues
  } = useForm<ContactForm>()
  const position: LatLngTuple = [54.31195, 18.59721]
  const icon = new Icon({
    iconUrl: markerIcon,
    iconSize: [25, 41], // size of the icon
    iconAnchor: [12, 40], // point of the icon which will correspond to marker's location
    popupAnchor: [0, -35] // point from which the popup should open relative to the iconAnchor
  })

  return <div className="main-content Contact">
    <div className='text-bg'>
      <div className='container-h'>
        <div className='container-v'>
          <h3>{i18n.t("contact.title")}</h3>
          <div className='text'>Niepołomicka, 80-180 Gdańsk, Polska</div>
          <div className='text'>biuro@tsn24.pl</div>
          <div className='text'>796 911 986</div>
          <form className="contact-form" onSubmit={handleSubmit(form => {

            const body = `Imię: ${form.name}
E-mail: ${form.email}
Telefon: ${form.phone}
Adres: ${form.address}
Treść:
${form.content}`;


            const url = "/email.php?"
              + "email=" + encodeURIComponent(form.email)
              + "&subject=" + encodeURIComponent(form.subject)
              + "&content=" + encodeURIComponent(body)


            fetch(url, { method: "GET", }).then(
              response => {
                if (response.status === 204) {
                  toast.success(i18n.t("contact.mail.success"));
                } else {
                  toast.error(i18n.t("contact.mail.failure"));
                }
              },
              () => toast.error(i18n.t("contact.mail.failure"))
            );


            // const href = "mailto:" + "biuro@tsn24.pl"
            // + "?subject=" + encodeURIComponent(subject)
            // + "&body=" + encodeURIComponent(body);

            // const a = document.createElement("a");
            // a.href = href;
            // a.click();
          })}>
            <label>
              {errors.name?.message ? <span className='error'>{errors.name?.message}</span> : ""}
              <input {...register("name", { required: "Pole wymagane" })} type="text" />
              <span>{i18n.t("contact.form.name")}</span>
            </label>
            <label>
              {errors.email?.message ? <span className='error'>{errors.email?.message}</span> : ""}
              <input {...register("email", { required: "Pole wymagane" })} type="email" />
              <span>E-mail</span>
            </label>
            <label>
              {errors.phone?.message ? <span className='error'>{errors.phone?.message}</span> : ""}
              <input {...register("phone", { required: "Pole wymagane" })} type="tel" />
              <span>{i18n.t("contact.form.phone")}</span>
            </label>
            <label>
              {errors.address?.message ? <span className='error'>{errors.address?.message}</span> : ""}
              <input {...register("address", { required: "Pole wymagane" })} type="text" />
              <span>{i18n.t("contact.form.address")}</span>
            </label>

            <label className='span-2'>
              {errors.subject?.message ? <span className='error'>{errors.subject?.message}</span> : ""}
              <input {...register("subject", { required: "Pole wymagane" })} type="text" />
              <span>{i18n.t("contact.form.subject")}</span>
            </label>
            <label className='span-2'>
              {errors.content?.message ? <span className='error'>{errors.content?.message}</span> : ""}
              <textarea {...register("content", { required: "Pole wymagane" })} style={{ height: "10em" }} maxLength={3000} />
              <span>{i18n.t("contact.form.content")}</span>
            </label>
            <button className='span-2 glow' role='submit'>Wyślij</button>
          </form>
        </div>
        <MapContainer center={position} zoom={17} scrollWheelZoom={false}
          dragging={false}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={position} icon={icon}>
            {/* <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup> */}
          </Marker>
        </MapContainer>
      </div>
    </div>
  </div>;
}
